import React from 'react';
import { ScrollLink } from 'react-scroll';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ChevronIcon from '../assets/icons/font-awesome/solid/chevron-down.svg';
import GetStartedButton from './GetStartedButton';

const Link = (props) => <div {...props}>{props.children}</div>;
const WrappedLink = ScrollLink(Link);

const Hero = (props) => {
  const {
    title,
    subtitle,
    description,
    linkTo,
    hideSignupButton = false,
    children,
  } = props;
  const { t } = useTranslation();

  return (
    <div className="pb-11 pb-lg-14 pt-20 bg-default-1 position-relative">
      <Container className="my-10">
        <Row className="align-items-center justify-content-center">
          <Col className="text-center">
            <h1 className="gr-text-2 font-weight-bold">{title}</h1>

            {subtitle && (
              <h2 className="gr-text-6 font-weight-bold mt-7 mb-0">
                {subtitle}
              </h2>
            )}
          </Col>
        </Row>

        <Row className="align-items-center justify-content-center">
          <Col lg="10" xl="9" className="text-center">
            {description && (
              <div className="gr-text-8 mt-10">{description}</div>
            )}
          </Col>
        </Row>

        {children}

        {!hideSignupButton && (
          <Row className="mt-11">
            <Col className="text-center">
              <GetStartedButton
                text={t('START NOW FOR FREE')}
                variant={2}
                utmTerm="main_cta"
                hideArrowIcon
                cardRequired
              />
            </Col>
          </Row>
        )}
      </Container>

      {linkTo && (
        <Container className="m-lg-20">
          <WrappedLink
            to={linkTo}
            smooth={true}
            offset={-50}
            duration={1500}
            className="bg-primary circle-40 gr-abs-bx-center mx-auto mt-200 goto"
            css={`
              cursor: pointer;
            `}
          >
            <span className="fa-svg-icon svg-baseline svg-color-white">
              <ChevronIcon />
            </span>
          </WrappedLink>
        </Container>
      )}
    </div>
  );
};

export default Hero;
